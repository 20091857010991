
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.kazan_slider_item {
    width: 100%;
    height: 510px;
    border-radius: 20px;
    overflow: hidden;

}
.kazan_slider_item img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.swiper-pagination-bullet {
    width: 6px!important;
    height: 6px!important;
    background: #ffffffB3!important;
    border-width: 1px;
    border-style: solid;
    border-color: #ffffffB3;
    opacity: 1!important;
}

.swiper-pagination-bullet-active {
    width: 6px!important;
    height: 6px!important;
    opacity: 1!important;
    border-color: #00A146;
}
