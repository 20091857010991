@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Light.eot');
    src: url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-Light.woff2') format('woff2'),
    url('../fonts/Montserrat-Light.woff') format('woff'),
    url('../fonts/Montserrat-Light.ttf') format('truetype'),
    url('../fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/Montserrat-Bold.woff') format('woff'),
    url('../fonts/Montserrat-Bold.ttf') format('truetype'),
    url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.eot');
    src: url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-Medium.woff2') format('woff2'),
    url('../fonts/Montserrat-Medium.woff') format('woff'),
    url('../fonts/Montserrat-Medium.ttf') format('truetype'),
    url('../fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.eot');
    src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('../fonts/Montserrat-SemiBold.woff') format('woff'),
    url('../fonts/Montserrat-SemiBold.ttf') format('truetype'),
    url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.kazan_sightseeings {
    width: 100%;
    margin-bottom: 50px;
}
.kazan_sightseeings_wrapper {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
}

.kazan_sightseeings_items_wrapper {
    width: 100%;
}

.kazan_sightseeings_item {
    width: 100%;
    margin-bottom: 40px;
    border: 10px solid #F6F6F6;
    background: #ffffff;
    border-radius: 40px;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
}

.kazan_sightseeings_item_child1 {
    width: 48%;
}
.kazan_sightseeings_item_child2 {
    width: 48%;

}

.kazan_sightseeings_item_child1_img {
    width: 100%;
    height: 208px;
    border-radius: 20px;
    overflow: hidden;
}
.kazan_sightseeings_item_child1_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.kazan_sightseeings_item_child2_img {
    width: 100%;
    height: 429px;
    border-radius: 20px;
    overflow: hidden;
}
.kazan_sightseeings_item_child2_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.kazan_sightseeings_item_child1_title_distance_info_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.kazan_sightseeings_item_child1_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 44px;
    font-weight: 700;
    color: #151411;
}
.kazan_sightseeings_item_child1_distance_info {
    font-family: 'Montserrat', sans-serif;
    font-size: 44px;
    font-weight: 500;
    color: #FF0000;
}
.kazan_sightseeings_item_child1_info {
    color: #333333;
    font-family: 'Montserrat', sans-serif;
    font-size: 17.4px;
    font-weight: 500;
    margin-bottom: 26px;
}

/*singlepage*/

.kazan_sightseeing_single_page {
    width: 100%;
}
.kazan_sightseeing_single_page_wrapper {
    width: 100%;
    max-width: 1240px;
    margin: 0  auto;
}
.kazan_sightseeing_single_page_slider_map_wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 50px;
}
.kazan_sightseeing_single_page_slider {
    width: 48%;
    height: 510px;
}

.kazan_sightseeing_single_page_info_map_wrapper {
    width: 48%;
}

.kazan_sightseeing_single_page_title_distance_info_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
}
.kazan_sightseeing_single_page_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 44px;
    font-weight: 700;
    color: #151411;
}
.kazan_sightseeing_single_page_distance_info {
    font-family: 'Montserrat', sans-serif;
    font-size: 44px;
    font-weight: 500;
    color: #FF0000;
}

.kazan_sightseeing_single_page_info {
    color: #333333;
    font-family: 'Montserrat', sans-serif;
    font-size: 17.4px;
    font-weight: 500;
    margin-bottom: 20px;
}
.kazan_sightseeing_single_page_map {
    width: 100%;
    height: 303px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.kazan_sightseeing_single_page_audio_list_items_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.kazan_sightseeing_single_page_audio_list_item {
    width: 31%;
    margin-bottom: 20px;
    margin-right: 11px;
    margin-left: 11px;
}

.kazan_sightseeing_single_page_audio_list_item_title_step_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.kazan_sightseeing_single_page_audio_list_item_step {
    margin-right: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #FF0000;
}
.kazan_sightseeing_single_page_audio_list_item_title {
    margin-right: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
}


.rhap_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-family: inherit;
    width: 100%;
    padding: 10px 15px;
    background-color: #F2F3F5!important;
     box-shadow: unset!important;
    border-radius: 40px!important;
}

.rhap_play-pause-button svg path {
    fill: #00A146!important;
}
.rhap_rewind-button svg path {
    fill: #00A146!important;
}

.rhap_forward-button svg path {
    fill: #00A146!important;
}

/*media*/
@media (max-width: 1280px) {
    .kazan_sightseeings_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .kazan_sightseeing_single_page_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}

@media (min-width: 1036px) and (max-width: 1200px) {

    .kazan_sightseeings_item {
        padding: 40px!important;
        border: 8px solid #F6F6F6!important;
        border-radius: 35px!important;
    }
    .kazan_sightseeings_item_child1_title {
        font-size: 34px!important;
    }
    .kazan_sightseeings_item_child1_distance_info {
        font-size: 34px!important;
    }
    .kazan_sightseeings_item_child1_info {
        font-size: 16px!important;
    }
    .kazan_sightseeings_item_child2_img {
        height: 408px!important;
    }
    .kazan_sightseeing_single_page_title {
        font-size: 36px!important;
    }
    .kazan_sightseeing_single_page_distance_info {
        font-size: 36px!important;
    }

}
@media (min-width: 956px) and (max-width: 1036px) {
    .kazan_sightseeings_item {
        padding: 40px 30px!important;
        border: 6px solid #F6F6F6!important;
        border-radius: 30px!important;
        margin-bottom: 35px!important;
    }
    .kazan_sightseeings_item_child1_title {
        font-size: 26px!important;
    }
    .kazan_sightseeings_item_child1_distance_info {
        font-size: 26px!important;
    }
    .kazan_sightseeings_item_child1_info {
        font-size: 15px!important;
    }
    .kazan_sightseeings_item_child2_img {
        height: 394px!important;
    }
    .kazan_sightseeing_single_page_title {
        font-size: 32px!important;
    }
    .kazan_sightseeing_single_page_distance_info {
        font-size: 32px!important;
    }

}
@media (min-width: 888px) and (max-width: 956px) {
    .kazan_sightseeings_item {
        padding: 35px 25px!important;
        border: 4px solid #F6F6F6!important;
        border-radius: 25px!important;
        margin-bottom: 30px!important;
    }
    .kazan_sightseeings_item_child1_title {
        font-size: 23px!important;
    }
    .kazan_sightseeings_item_child1_distance_info {
        font-size: 23px!important;
    }
    .kazan_sightseeings_item_child1_info {
        font-size: 14px!important;
    }
    .kazan_sightseeings_item_child2_img {
        height: 385px!important;
    }
    .kazan_sightseeing_single_page_title {
        font-size: 28px!important;
    }
    .kazan_sightseeing_single_page_distance_info {
        font-size: 28px!important;
    }
    .kazan_sightseeing_single_page_info {
        font-size: 16px!important;
    }
}
@media (min-width: 480px) and (max-width: 888px) {
    .kazan_sightseeings_item {
        padding: 0 0 20px 0!important;
        box-shadow: 0 0 15px 0  #00000033;
        border: 0!important;
        border-radius: 20px!important;
        margin-bottom: 25px!important;
    }
    .kazan_sightseeings_item_child1_title {
        font-size: 20px!important;
    }
    .kazan_sightseeings_item_child1_distance_info {
        font-size: 20px!important;
    }
    .kazan_sightseeings_item_child1_info {
        font-size: 14px!important;
        margin-bottom: 0!important;
    }
    .kazan_sightseeing_single_page_title {
        font-size: 25px!important;
    }
    .kazan_sightseeing_single_page_distance_info {
        font-size: 25px!important;
    }
    .kazan_sightseeing_single_page_info {
        font-size: 16px!important;
    }

    .kazan_sightseeings_item_child2 {
        display: none!important;
    }
    .kazan_sightseeings_item_child1 {
        width: 100% !important;
    }
    .kazan_sightseeings_item_child1 {
        display: flex;
        flex-direction: column-reverse;
    }
    .kazan_sightseeings_item_child1_img {
        width: 100%;
       border-radius: 15px!important;

    }
    .kazan_sightseeings_item_child1_info_box {
        padding: 10px;
    }
}
@media (min-width: 426px) and (max-width: 480px) {
    .kazan_slider_item {
        height: 430px!important;
    }
    .kazan_sightseeing_single_page_title {
        font-size: 22px!important;
    }
    .kazan_sightseeing_single_page_distance_info {
        font-size: 22px!important;
    }
    .kazan_sightseeing_single_page_info {
        font-size: 15px!important;
    }
}
@media (min-width: 375px) and  (max-width: 426px) {
    .kazan_slider_item {
        height: 400px!important;
    }
    .kazan_sightseeing_single_page_title {
        font-size: 20px!important;
    }
    .kazan_sightseeing_single_page_distance_info {
        font-size: 20px!important;
    }
    .kazan_sightseeing_single_page_info {
        font-size: 14px!important;
    }
}
@media  (max-width: 375px) {
    .kazan_slider_item {
        height: 350px!important;
    }
    .kazan_sightseeing_single_page_title {
        font-size: 18px!important;
    }
    .kazan_sightseeing_single_page_distance_info {
        font-size: 18px!important;
    }
    .kazan_sightseeing_single_page_info {
        font-size: 14px!important;
    }
}
@media (max-width: 480px) {
    .kazan_sightseeings_item {
        padding: 0 0 20px 0!important;
        box-shadow: 0 0 15px 0  #00000033;
        border: 0!important;
        border-radius: 15px!important;
        margin-bottom: 20px!important;
    }
    .kazan_sightseeings_item_child1_title {
        font-size: 18px!important;
    }
    .kazan_sightseeings_item_child1_distance_info {
        font-size: 18px!important;
    }
    .kazan_sightseeings_item_child1_info {
        font-size: 12px!important;
        margin-bottom: 0!important;
    }


    .kazan_sightseeings_item_child2 {
        display: none!important;
    }
    .kazan_sightseeings_item_child1 {
        width: 100% !important;
    }
    .kazan_sightseeings_item_child1 {
        display: flex;
        flex-direction: column-reverse;
    }
    .kazan_sightseeings_item_child1_img {
        width: 100%;
       border-radius: 15px!important;

    }
    .kazan_sightseeings_item_child1_info_box {
        padding: 10px;
    }
}


.kazan_sightseeings_item_child1_info_box {
    width: 100%;
}

@media (max-width: 425px) {

    .kazan_sightseeings_item_child1_img {
        height: 180px!important;
    }
    .kazan_sightseeing_single_page_audio_list_item_step {
        font-size: 16px!important;
    }
    .kazan_sightseeing_single_page_audio_list_item_title {
        font-size: 16px!important;
    }
    .kazan_sightseeing_single_page_map {
        border-radius: 15px!important;
    }
    .kazan_slider_item {
        border-radius: 15px!important;
    }
}

@media (min-width: 581px) and (max-width: 990px) {
    .kazan_sightseeing_single_page_slider_map_wrapper {
        flex-wrap: wrap;
    }
    .kazan_sightseeing_single_page_slider {
        width: 100% !important;
        margin-bottom: 20px;
        height: unset!important;
    }
    .kazan_sightseeing_single_page_info_map_wrapper {
        width: 100% !important;

    }
    .kazan_sightseeing_single_page_audio_list_items_wrapper {
        justify-content: space-between!important;
    }
    .kazan_sightseeing_single_page_audio_list_item {
        width: 48% !important;
        margin-left: unset!important;
        margin-right: unset!important;
    }
}
@media  (max-width: 581px) {
    .kazan_sightseeing_single_page_audio_list_items_wrapper {
        justify-content: space-between!important;
    }
    .kazan_sightseeing_single_page_slider_map_wrapper {
        flex-wrap: wrap;
    }
    .kazan_sightseeing_single_page_slider {
        width: 100% !important;
        margin-bottom: 20px;
        height: unset!important;
    }
    .kazan_sightseeing_single_page_info_map_wrapper {
        width: 100% !important;

    }
    .kazan_sightseeing_single_page_audio_list_item {
        width: 100% !important;
        margin-left: unset!important;
        margin-right: unset!important;
    }

}


.kazan_sightseeings_load_more_btn {
    width: 100%;
    max-width: 280px;
    background-color: #FF0000;
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    border: none;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

}

.kazan_sightseeings_load_more_btn svg {
    margin-left: 5px;
}
