@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Light.eot');
    src: url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-Light.woff2') format('woff2'),
    url('../fonts/Montserrat-Light.woff') format('woff'),
    url('../fonts/Montserrat-Light.ttf') format('truetype'),
    url('../fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/Montserrat-Bold.woff') format('woff'),
    url('../fonts/Montserrat-Bold.ttf') format('truetype'),
    url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.header {
    width: 100%;
    padding-top: 21px;
    margin-bottom: 31px;
    position: relative;
}

.header_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1240px;
    margin: 0 auto;
    position: relative;
}

.header_logo {
    position: absolute;
    left: 0;
    top: -10px;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    width: 46px;
    height: 48px;
}
.header_logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header_title {
    font-family: 'Montserrat', sans-serif;
    color: #00A146;
    font-weight: 700;
    font-size: 26px;
}

.header_title_span {
    font-family: 'Montserrat', sans-serif;
    color: #EC0210;
    font-weight: 300;
    font-size: 26px;
}


/*media*/

@media (max-width: 1280px) {
    .header_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}

@media (max-width: 425px) {
    .header_logo {
        width: 38px!important;
        height: 40px!important;
        top: -5px!important;
    }
}
